import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getWindowLocationPathname } from "../services/utils-service";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import authSlice from "../store/slices/auth";
import { RootState } from "../store/store";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  const auth = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const response_type = searchParams.get("response_type");
  const client_id = searchParams.get("client_id");
  const redirect_uri = searchParams.get("redirect_uri");
  const scope = searchParams.get("scope");
  const code_challenge = searchParams.get("code_challenge");
  const code_challenge_method = searchParams.get("code_challenge_method");
  const nonce = searchParams.get("nonce");
  const state = searchParams.get("state");
  const claims = searchParams.get("claims");

  const url = getWindowLocationPathname();
  if (
    url.includes("provider") ||
    url.includes("service") ||
    url.includes("video") ||
    url.includes("article") ||
    url.includes("package") ||
    url.includes("content")
  ) {
    dispatch(authSlice.actions.setRedirectUrl(url));
  }

  const creatingEmployer = url.includes("createEmployerWelcome");

  useEffect(() => {
    if (!auth.account) {
      if (response_type) {
        let path = "/login?";
        path = path + "response_type=" + response_type + "&";
        path = path + "client_id=" + client_id + "&";
        path = path + "redirect_uri=" + redirect_uri + "&";
        path = path + "scope=" + scope + "&";
        path = path + "code_challenge=" + code_challenge + "&";
        path = path + "code_challenge_method=" + code_challenge_method + "&";
        path = path + "nonce=" + nonce + "&";
        path = path + "state=" + state + "&";
        path = path + "claims=" + claims + "&";
        navigate(path);
      } else {
        navigate(`/login${creatingEmployer ? "?create_employer=true" : ""}`);
      }
    }
  }, [
    auth.account,
    claims,
    client_id,
    code_challenge,
    code_challenge_method,
    creatingEmployer,
    navigate,
    nonce,
    redirect_uri,
    response_type,
    scope,
    state,
  ]);

  return children;
};

export default ProtectedRoute;
